<template>
  <div class="main-container">
    <div v-if="this.researchItems.length > 0" class="main-container">
      <mds-layout-grid class="hero-banner">
        <mds-row class="banner-row" align-vertical="center">
          <mds-col>
            <div class="title">
              <span>{{ selItem.title }}</span>
            </div>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
      <mds-layout-grid class="about-section">
        <mds-row>
          <mds-col class="section-text-container">
            <div class="section-text" v-html="selItem.introduction"></div>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
      <mds-layout-grid class="form">
        <mds-row align-horizontal="center">
          <mds-col :cols="10" :cols-at-m="5" :cols-at-l="5" :cols-at-xl="4">
            <div>
              <p class="list-title">
                Download this research {{ researchType }} to read more about:
              </p>
              <p v-if="selItem.keyPointsTitle" class="key-points-title">{{ selItem.keyPointsTitle }}</p>
              <ul>
                <li v-for="keyPoint in selItem.keyPoints" :key="keyPoint">
                  {{ keyPoint }}
                </li>
              </ul>
            </div>
          </mds-col>
          <mds-col :cols="10" :cols-at-m="5" :cols-at-l="5" :cols-at-xl="4">
            <div class="thankyou-msg-hide" ref="thankyou">
              Thank you for your interest!<br /><br />
              <span class="thankyou-msg-download-msg"
                >Your download will start shortly.
              </span>
              <a
                :href="researchPaperUrl"
                id="downloadpaper"
                target="_blank"
              ></a>
            </div>
            <div ref="form" class="form-show">
              <p class="form-title">Sign up to receive your copy:</p>
              <mds-form ref="researchForm" size="small" id="researchForm">
                <mds-input
                  v-bind="formData.name"
                  v-model="formData.name.value"
                ></mds-input>
                <mds-input
                  v-bind="formData.userName"
                  v-model="formData.userName.value"
                ></mds-input>
                <mds-combo-box
                  v-bind="formData.country"
                  label="Country"
                  :data-set="countries"
                  v-model="selectedCountry"
                  required
                ></mds-combo-box>
                <mds-button
                  size="small"
                  variation="primary"
                  v-on:click="onSubmit($event)"
                  >Submit</mds-button
                >
              </mds-form>
            </div>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
      <div class="social-share">
        <table border="0" cellspacing="2" cellpadding="6">
          <tr>
            <td colspan="4">Share this page</td>
          </tr>
          <tr>
            <td>
              <a href="#" v-on:click="shareLinkClick($event)" target="_blank"
                ><ShareIcon share-icon-id="twitter"
              /></a>
            </td>
            <td>
              <a href="#" v-on:click="shareLinkClick($event)" target="_blank"
                ><ShareIcon share-icon-id="linkedin"
              /></a>
            </td>
            <td>
              <a href="#" v-on:click="shareLinkClick($event)" target="_blank"
                ><ShareIcon share-icon-id="email"
              /></a>
            </td>
            <td>
              <a href="#" v-on:click="shareLinkClick($event)" target="_blank"
                ><ShareIcon share-icon-id="link"
              /></a>
              <mds-tooltip
                triggered-by="link"
                v-model="tooltipToggle"
                :position="['right-center']"
                size="small"
                variation="error"
                >URL copied to clipboard</mds-tooltip
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-else-if="this.researchItems.length == 0 && !loading" class="section-empty-state">
      <mds-empty-state title="No Data Found" message="" />
    </div>
    <lock-screen-loader v-if="loading"/>
  </div>
</template>
<script>
import { MdsButton } from "@mds/button";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import MdsInput from "@mds/input";
import MdsComboBox from "@mds/combo-box";
import MdsTooltip from '@mds/tooltip';
import usersData from "../data/data.json";
import trackEvent, { isExternalUser } from "@/utils/googleAnalytics";
import { events } from "@/config/events";
import { downloadArticleEloquaRegister } from "@/services/formService.js";
import downloadEloquaRegisterData from "@/data/downloadEloquaRegisterData.json"
import MdsEmptyState from "@mds/empty-state";
import LockScreenLoader from "@/components/Common/LockScreenLoader/LockScreenLoader.vue";
import alertEvent from "@/services/alertEvent";
import {
  getResearchList,
  getResearchFileUrl,
} from "@/services/researchService.js";

export default {
  name: "Download-Research",
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsForm,
    MdsInput,
    MdsComboBox,
    MdsButton,
    MdsTooltip,
    ShareIcon: () =>import('@/components/ShareIcon.vue'),
    MdsEmptyState,
    LockScreenLoader
  },
  props: {
    id: {
      type: String,
      default: "",
      required: false,
    },
    size: {
      type: String,
      default: "medium",
    },
  },
  data() {
    return {
      tooltipToggle: false,
      countries: usersData.countries,
      selectedCountry: [],
      researchItems: [],
      researchPaperUrl: "",
      loading: false,
      formData: {
        name: {
          id: "name",
          name: "name",
          label: "Name",
          required: true,
          error: false,
          errorText: ["Please type your full name"],
          value: "",
        },
        userName: {
          id: "emailAddress",
          name: "emailAddress",
          label: "Email Address",
          required: true,
          error: false,
          errorText: ["Please type your email address"],
          value: "",
        },
        country: {
          id: "country",
          name: "country",
          label: "Country",
          required: true,
          placeholder: "Select a Country",
          error: false,
          errorText: ["Please select the country you are from"],
        },
        subject: {
          id: "subject",
          label: "",
          required: false,
          error: false,
          hidden: true,
          value: "[Morningstar Credit] Research Paper Downloaded",
        },
      },
    };
  },
  created() {
    this.getResearchList();
  },
  computed: {
    selItem() {
      var selIndex = 0;
      for (var i = 0; i < this.researchItems.length; i++) {
        if (this.researchItems[i].id === this.$route.query.id) {
          selIndex = i;
          break;
        }
      }

      return this.researchItems[selIndex];
    },
    researchType() {
      return this.selItem.type.toLowerCase();
    },
  },
  methods: {
    shareUrl: function(medium) {
      if (medium === 'twitter') {
        return `https://twitter.com/intent/tweet?text=${this.selItem.title}&url=${location.href}`;
      } else if (medium === 'linkedin') {
        return `https://www.linkedin.com/shareArticle?mini=true&url=${location.href}`;
      } else if (medium === 'email') {
        return `mailto:?body=${location.href}`;
      }
    },
    shareLinkClick: function(event) {
      event.preventDefault();
      if (event.target.id === 'link') {
        navigator.clipboard.writeText(location.href);
        this.tooltipToggle = true;
        setTimeout(() => {
          this.tooltipToggle = false;
        },1000);
      } else {
        var windowFeatures = "menubar=no,location=no,resizable=no,scrollbars=no,toolbar=no,status=no,width=500,height=500";
        var url = this.shareUrl(event.target.id);
        window.open(url, "", windowFeatures);
      }
    },
    validateForm: function () {
      var isValid = true;
      var reg =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

      if (this.$el.querySelector("#name").value === "") {
        this.formData.name.error = true;
        isValid = false;
      } else {
        this.formData.name.error = false;
      }
      if (this.$el.querySelector("#emailAddress").value === "") {
        this.formData.userName.errorText = ["Please type your email address"];
        this.formData.userName.error = true;
        isValid = false;
      } else if (!reg.test(this.$el.querySelector("#emailAddress").value)) {
        this.formData.userName.errorText = [
          "A valid email address is required",
        ];
        this.formData.userName.error = true;
        isValid = false;
      } else {
        this.formData.userName.error = false;
      }
      if (this.selectedCountry.length === 0) {
        this.formData.country.error = true;
        isValid = false;
      } else {
        this.formData.country.error = false;
      }
      return isValid;
    },

    onSubmit: async function (event) {
      event.preventDefault();
      if (this.validateForm()) {
        await this.getResearchPaperPreSignedUrl();
        if (this.researchPaperUrl != "") {
          if (isExternalUser(this.formData.userName.value)) {
            this.trackArticleDownloadEvent();
          }
          var postData = {};
          postData[this.formData.name.label] = this.formData.name.value;
          postData[this.formData.userName.label] = this.formData.userName.value;
          postData[this.formData.country.label] = this.selectedCountry;
          postData["Subject"] = "[Morningstar Credit] Research Paper Downloaded";
          postData["Research Paper"] = this.selItem.title;

          const name = this.formData.name.value.split(' ');
          const firstName = name[0];
          const lastName = this.formData.name.value.substring(firstName.length + 1);
          const postEloquaData = downloadEloquaRegisterData;
          postEloquaData["fieldValues"]?.forEach((item) => {
            switch (item.name) {
              case "firstName":
                item.value = firstName?.substring(0, 35);
                break;
              case "lastName":
                if (lastName.length === 0) {
                  item.value = " ";
                } else {
                  item.value = lastName?.substring(0, 35);
                }
                break;
              case "emailAddress":
                item.value = this.formData.userName?.value;
                break;
              case "country":
                item.value = this.selectedCountry?.toString().substring(0, 200);
                break;
              case "researchPaperName":
                item.value = this.selItem.title?.substring(0, 200);
                break;
            }
          });
          downloadArticleEloquaRegister(postEloquaData);

          var apiUrl = `${process.env.VUE_APP_API_BASE_URL}/internalmail`;

          fetch(apiUrl, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(postData),
            headers: {
              "Content-Type": "application/json",
              "Strict-Transport-Security":
                "max-age=31536000; includeSubDomains",
            },
          }).then(function (response) {
            return response.json();
          });

          this.$refs.thankyou.attributes["class"].value = "thankyou-msg-show";
          this.$refs.form.attributes["class"].value = "form-hide";
          window.setTimeout(function () {
            document.getElementById("downloadpaper").click();
          }, 2000);
        } else {
          alertEvent.$emit("alert", {
            title: "Error",
            message: "It looks like something went wrong",
          });
        }
      }
    },
    trackArticleDownloadEvent() {
      trackEvent(
        events.event.RESEARCH_ARTICLE_DOWNLOAD,
        events.category.RESEARCH_ARTICLE_DOWNLOAD,
        events.action.CLICK,
        events.label.RESEARCH_ARTICLE_DOWNLOAD,
        {
          article_title: this.selItem.title,
          download_location: "research article page",
        }
      );
    },
    getResearchList() {
      this.loading = true;
      getResearchList()
        .then(async (resp) => {
          if (resp?.length > 0) {
            this.researchItems = resp;
            this.setDocumentTitle();
          } else {
            this.researchItems = [];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getResearchPaperPreSignedUrl(){
      await getResearchFileUrl(this.selItem?.researchPaperUrl?.split("/").pop())
      .then(async (resp) => {
        if (resp?.data?.preSignedUrl){
          this.researchPaperUrl = resp?.data?.preSignedUrl;
        } else {
          this.researchPaperUrl = "";
        }
      });
    },
    setDocumentTitle() {
      for (var i = 0; i < this.researchItems.length; i++) {
        if (this.researchItems[i].id === this.$route.query.id) {
          document.title = this.researchItems[i].title + " - Morningstar Credit (previously MCIA)";
          break;
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@mds/constants";
@import "@mds/fonts";
@import "@mds/typography";

.main-container {
  height: auto;
  margin: 0 auto;
}

.hero-banner {
  box-sizing: border-box;
  background-image: url("../assets/inner-screen-banner.png");
  height: 170px;
  border: 1px solid #979797;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-banner .title {
  @include mds-level-1-heading($bold: true);
  color: $mds-color-white;
  text-align: center;
}

.about-section {
  border-bottom: $mds-border-separator;
}

.section-text-container {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.section-text {
  @include mds-level-4-heading();
  color: $mds-text-color-primary;
  width: 60%;
  text-align: center;
}

.form {
  margin-top: 40px;
  margin-bottom: 100px;
}

.sample-image {
  text-align: right;
}

.list-title {
  @include mds-level-4-heading($bold: true);
}

.form-title {
  @include mds-level-5-heading();
}

ul {
  @include mds-body-text-m();
  line-height: 1.5;
}

li {
  margin-bottom: 10px;
}

.thankyou-msg-show {
  @include mds-level-4-heading($bold: true);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thankyou-msg-hide {
  display: none;
}

.thankyou-msg-download-msg {
  @include mds-level-6-heading();
}

.form-show {
  display: block;
}

.form-hide {
  display: none;
}

.social-share {
    margin: 0 auto;
    padding: $mds-space-1-x 0;
    text-align: center;
    @include mds-level-4-heading();
    color: $mds-text-color-secondary;
    background-color: $mds-background-color-light-gray;
}

table {
  margin: auto;
}

.key-points-title {
  @include mds-level-5-heading();
}

linkTooltip {
  @include mds-body-text-s();
}

.section-loader, .section-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}

</style>