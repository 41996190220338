import { render, staticRenderFns } from "./AccessDenied.vue?vue&type=template&id=aad50e3c&scoped=true&"
import script from "./AccessDenied.vue?vue&type=script&lang=js&"
export * from "./AccessDenied.vue?vue&type=script&lang=js&"
import style0 from "./AccessDenied.vue?vue&type=style&index=0&id=aad50e3c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aad50e3c",
  null
  
)

export default component.exports