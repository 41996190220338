<template>
  <div class="news-flash-list">
    <mds-list-group size="large">
      <mds-list-group-item
        v-for="(item, index) in items"
        class="news-flash-list-item"
        :key="`news-flash-item-${index}`"
        :active="item.id === selectedId"
        @click.native="handleItemSelect(item)"
      >
        <div class="news-flash-list-item-date">{{ item.date }}</div>
        <div class="news-flash-list-item-title">{{ item.name }}</div>
      </mds-list-group-item>
    </mds-list-group>
  </div>
</template>

<script>
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';

export default {
  name: 'Research',
  components: {
    MdsListGroup,
    MdsListGroupItem
  },
  props: {
    selectedItem: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedId: this.selectedItem,
    };
  },
  methods: {
    handleItemSelect(item) {
      this.selectedId = item.id;
      this.$emit('onItemSelect', item.id);
      window.history.replaceState({}, '', encodeURIComponent(item.id));
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.news-flash-list {
  &-item {
    &-date {
      @include mds-body-text-m();
      color: $mds-interactive-color-secondary-hover;
    }
    &-title {
      @include mds-body-text-m(bold);
    }
  }
}
</style>
