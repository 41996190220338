<template>
  <div v-if="video">
    <div v-if="video.title != null" class="video-title">{{video.title}}</div>
    <div v-if="video.showBanner">
      <Banner
        :title="analyticsData.analyticsBanner.title"
        :description="analyticsData.analyticsBanner.description"
        :showButton="true"/>
    </div>
    <div v-if="video.linkType == 'directLink'">
      <iframe 
        width="631"
        height="400"
        class="link-video-player"
        :src="video.name" type="video/mp4">
      </iframe>
    </div>
    <div v-else>
      <video controls class="video-player">
        <source :src="videoSource" type="video/mp4">
        Your browser does not support HTML video.
      </video>
    </div>
  </div>
</template>

<script>
import videosList from "../data/videos.json";
import Banner from '@/components/CreditAnalytics/Banner.vue';
import AnalyticsData from "@/data/creditAnalytics.json";

export default {
  name: "VideoDetails",
  components: {
    Banner
  },
  props: {
    videoId: {
      type: String,
      default: null,
    }
  },
  data(){
    return {
      video: null,
      analyticsData: AnalyticsData
    };
  },
  created() {
    this.video = videosList?.find(video => video.id.toLowerCase() == this.videoId.toLowerCase());
  },
  computed: {
    videoSource() {
      return process.env.VUE_APP_VIDEOS_BASE_URL+this.video?.name;
    }
  }
};
</script>

<style lang="scss">
@import '@mds/typography';

.video-title {
  @include mds-level-2-heading;
  text-align: center;
  padding-top: $mds-space-2-x;
}
.video-player {
  max-width: 100%;
  height: 400px;
  margin: auto;
  display: block;
  padding-top: $mds-space-2-x;
  padding-bottom: $mds-space-7-x;
  padding-right: $mds-space-3-x;
  padding-left: $mds-space-3-x;
  text-align: center;
}
.link-video-player {
  height: 400px;
  margin: auto;
  display: block;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
  max-width: -webkit-fill-available;
}
</style>