<template>
  <div class="mcia-outer-container">
    <div class="mcia-inner-container">
      <mds-layout-grid class="hero-banner">
        <mds-row
          class="hero-banner-row"
          align-vertical="center"
          align-horiontal="center"
        >
          <mds-col :cols="11">
            <div v-html="title" class="hero-banner-row-title"></div>
            <div v-html="description" class="hero-banner-row-desc"></div>
            <div v-if="showButton">
              <router-link class="hero-banner-row-link-style" :to="mciaLinksData.analytics">
                <mds-button class="button-css" variation="primary" size="large">Know More</mds-button>
              </router-link>
            </div>
          </mds-col>
        </mds-row>
        <mds-row>
          <mds-col :cols="12">
            <div class="hero-banner-row-poweredby">
              {{analyticsData.analyticsBanner.poweredBy}}
              <img
                class="hero-banner-row-img"
                :src="imagePath"
              />
            </div>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import AnalyticsData from "@/data/creditAnalytics.json";
import mciaData from '@/data/constants.json';
import { MdsButton } from '@mds/button';

export default {
  name: 'Banner',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton
  },
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    description: {
      type: String,
      default: '',
      required: true,
    },
    showButton: {
      type: Boolean,
      default: false,
      // required: true,
    },
  },
  computed: {
    imagePath() {
      return require('../../assets/' + this.analyticsData.analyticsBanner.imageFileName);
    }
  },
  data() {
    return {
      analyticsData: AnalyticsData,
      mciaLinksData: mciaData?.links
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hero-banner {
  height: 100%;
  width: 100%;
  &-row {
    height: 82%;
    text-align: left;
    &-title {
      @include mds-level-1-heading($bold: true);
      font-weight: 700;
      color: $mds-color-white;
      text-align: left;
      padding-bottom: $mds-space-1-x;
      margin-top: 45px;
    }
    &-desc {
      @include mds-body-text-l();
      font-weight: 300;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.01em;
      color: $mds-color-white;
      text-align: left;
    }
    &-poweredby {
      @include mds-body-text-l();
      letter-spacing: 0.01em;
      color: $mds-color-white;
      float: right;
      display: flex;
      align-items: center;
      // padding-top: 180px;
    }
    &-img{
      padding-left: $mds-space-1-x;
    }
    &-link-style {
      @include mds-link($underline: false);
    }
  }
}

.button-css {
  background-color: $mds-background-color-white;
  color: $mds-color-black;
  margin-top: $mds-space-2-and-a-half-x;
}
.mcia-outer-container {
  box-sizing: border-box;
  background-image: url('../../assets/analytics-hero-banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 248px;
  border: $mds-border-container;
  .mcia-inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}
</style>
