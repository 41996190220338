<template>
  <div class="terms-container">
      <table cellpadding="0" cellspacing="0" border="0">
        <tr>
          <td class="section-title">
            Terms and Conditions
          </td>
        </tr>
      <tr>
        <td class="terms-and-conditions">
            These Terms and Conditions (the “Terms”) contain the terms, covenants, conditions and provisions pursuant to which users may access and use this website (the "Site") and the analyses, disclosures, facts, statements, and other information (the "Content") displayed on the Site. The Site contains documents created by Morningstar Credit Analytics LLC (“Morningstar Credit”), its affiliates and/or third-party licensors (Morningstar Credit, together with its affiliates and third-party licensors are collectively referred to herein as the  “Morningstar Parties”).
            <br><br>
            Morningstar Credit may change these Terms at any time without notice. Changes will be posted at <a href="https://credit.morningstar.com" target="_blank">www.credit.morningstar.com</a> under "Terms and Conditions”. Your use of the Site after any changes have been posted will constitute your agreement to the modified Terms and all of the changes. You should periodically read these Terms for any changes. The effective date of these Terms is identified below.
            <br>
            <ol>
                <li>Access</li>
                By using this Site, you acknowledge that you have read these Terms, understand them, and agree to be bound by their terms and conditions.
                <br><br>
                <li> Privacy Policy</li>
                By using this Site, you acknowledge that you have read and understand the Privacy Policy and consent to Morningstar Credit’s use of your Personal Data set out therein (including, with respect to Site-users in the European Union, the transfer of your Personal Data outside the European Economic Area).If you do not agree to these Terms or the Privacy Policy, you shall neither access nor use this Site.
                <br><br>
                Please see the <a href="https://www.morningstar.com/company/privacy-center" target="_blank">Privacy Policy</a> for a summary of Morningstar Credit’s personal data collection and use practices with respect to the Site.
                <br><br>
                <li>Intellectual Property Rights</li>
                All trade names, trademarks, service marks, images and logos on materials, including electronic facsimiles, published by Morningstar Credit are proprietary to their respective owners and are protected by applicable trademark and copyright laws. Any unauthorized use of marks or graphics owned by the Morningstar Parties is strictly prohibited. Any of the trademarks, service marks or logos (collectively, the "Marks") displayed on the Site may be registered or unregistered marks of the Morningstar Parties. Nothing contained on this Site should be construed as granting any license or right to use any of the Marks displayed on the Site without the express written permission of Morningstar Credit.
                <br><br>
                Applicable legislation, including The (U.S.) Digital Millennium Copyright Act of 1998 (the “DMCA”), may provide recourse for copyright owners who believe that material appearing on the internet infringes their rights under copyright law. If you believe in good faith that materials available on the Site infringe your copyright, you (or your agent) may send Morningstar Credit a notice requesting that Morningstar Credit remove the material or block access to it. If you believe in good faith that someone has wrongly filed a notice of copyright infringement against you, you may send Morningstar Credit a counter-notice. Notices and counter-notices must meet the then-current statutory requirements, if any, imposed by the applicable legislation. Notices and counter-notices should be sent by mail to Morningstar Credit as later set out in these Terms. We suggest that you consult your legal advisor before filing a notice or counter-notice.
                <br><br>
                <li>Assumption of Risk</li>
                You use the internet solely at your own risk and subject to all applicable local, state, national, and international laws and regulations. While Morningstar Credit has endeavored to create a secure and reliable website, please be advised that the confidentiality of any communication or material transmitted to/from this Site over the internet cannot be guaranteed. Accordingly, the Morningstar Parties are not responsible for the security of any information transmitted via the internet. You assume the sole and complete risk of using the Site.
                <br><br>
                <li>Links to other websites</li>
                You may, through hypertext or other computer links, gain access to websites operated by persons other than Morningstar Credit. Such hyperlinks are provided for convenience only, and are the exclusive responsibility of the owners of such websites. Morningstar Credit does not endorse the content, the operator or operations of third party websites. Morningstar Credit is not responsible for the content or operation of such websites and Morningstar Credit shall have no liability to you or any other person or entity for the use of third party websites. A hyperlink from this Site to another website does not imply or mean that Morningstar Credit endorses the content on that website or the operator or operations of that site. You are solely responsible for determining the extent to which you may use any content at any other websites to which you link from this Site. Morningstar Credit assumes no responsibility for the use of third party software on the Site and shall have no liability whatsoever to any person or entity for the accuracy or completeness of any outcome generated by such software.
                <br><br>
                <li>Abuse and use of the Site</li>
                By accessing this Site, you agree not to:
                <br>
                ( i ) use or attempt to use any automated device, program, tool, algorithm, process, or methodology or manual process having similar processes or functionality, to access, acquire, copy, or monitor any portion of the Site, or Content found on or accessed through the Site without the prior express written consent of Morningstar Credit;
                <br>
                ( ii ) obtain or attempt to obtain through any means any materials or information on the Site that have not been intentionally made publicly available either by their public display on the Site or through their accessibility by a visible link on the Site;
                <br>
                ( iii ) attempt to violate the security of the Site or attempt to gain unauthorized access to the Site, data, materials, information, computer systems or networks connected to any Morningstar Credit server, through hacking, password mining or any other means;
                <br>
                ( iv ) interfere or attempt to interfere with the operation of the Site;
                <br>
                ( v ) take or attempt any action that, in the sole discretion of Morningstar Credit, imposes or may impose an unreasonable or disproportionately large load or burden on the Site.
                <br><br>
                <li>Changes to the Site</li>
                Morningstar Credit may discontinue or change the Site, or its availability to you, at any time. These Terms will survive any such discontinuation.
                <br><br>
                <li>Passwords</li>
                Certain portions of the Site may require a password. As part of the registration process, you may be required to select a username (email address) and password and provide Morningstar Credit with accurate, complete and up-to-date information. Anyone with knowledge of your password can gain access to the restricted portions of the Site and the information available to you. Accordingly, you must keep your password secret. By agreeing to these Terms, you agree to be solely responsible for the confidentiality and use of your respective password, as well as for any communications entered through this Site using your password. You will also promptly notify Morningstar Credit if you become aware of any loss or theft of your Password or any unauthorized use of your password. Morningstar Credit reserves the right to delete or change a password at any time and for any reason.
                Access and use of the Site by subscribers to the Site, including the use of usernames and passwords, is further subject to the terms and conditions of any applicable subscription agreement between Morningstar Credit and you or your organization.
                <br><br>
                <li>Disclaimer</li>
                Morningstar Credit does not guarantee or warrant the accuracy, timeliness, or completeness of any Content displayed on the Site.
                <br><br>
                Please see the <router-link to="/disclaimer" >Disclaimer </router-link>for the full disclaimer.
                <br><br>
                <li>CUSIP Database</li>
                CUISP: You agree and acknowledge that the CUSIP Database and the information contained therein is and shall remain valuable intellectual property owned by, or licensed to, CUSIP Global Services ("CGS") and the American Bankers Association ("ABA"), and that no proprietary rights are being transferred to you in such materials or in any of the information contained therein. Any use by you outside of the clearing and settlement of transactions requires a license from CGS, along with an associated fee based on usage.
                <br><br>
                You agree that misappropriation or misuse of such materials will cause serious damage to CGS and ABA, and that in such event money damages may not constitute sufficient compensation to CGS and ABA; consequently, you agree that in the event of any misappropriation or misuse, CGS and ABA shall have the right to obtain injunctive relief in addition to any other legal or financial remedies to which CGS and ABA may be entitled.
                <br><br>
                You agree not publish or distribute in any medium the CUSIP Database or any information contained therein or summaries or subsets thereof to any person or entity except in connection with the normal clearing and settlement of security transactions. You further agree that the use of CUSIP numbers and descriptions is not intended to create or maintain, and does not serve the purpose of the creation or maintenance of, a master file or database of CUSIP descriptions or numbers for itself or any third party recipient of such service and is not intended to create and does not serve in any way as a substitute for the CUSIP MASTER TAPE, PRINT, DB, INTERNET, ELECTRONIC, CD-ROM Services and/or any other future services developed by the CGS.
                <br><br>
                NEITHER CGS, ABA NOR ANY OF THEIR AFFILIATES MAKE ANY WARRANTIES, EXPRESS OR IMPLIED, AS TO THE ACCURACY, ADEQUACY OR COMPLETENESS OF ANY OF THE INFORMATION CONTAINED IN THE CUSIP DATABASE. ALL SUCH MATERIALS ARE PROVIDED TO USER ON AN "AS IS" BASIS, WITHOUT ANY WARRANTIES AS TO MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE NOR WITH RESPECT TO THE RESULTS WHICH MAY BE OBTAINED FROM THE USE OF SUCH MATERIALS. NEITHER CGS, ABA NOR THEIR AFFILIATES SHALL HAVE ANY RESPONSIBILITY OR LIABILITY FOR ANY ERRORS OR OMISSIONS NOR SHALL THEY BE LIABLE FOR ANY DAMAGES, WHETHER DIRECT OR INDIRECT, SPECIAL OR CONSEQUENTIAL, EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL THE LIABILITY OF CGS, ABA OR ANY OF THEIR AFFILIATES PURSUANT TO ANY CAUSE OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE, EXCEED THE FEE PAID BY USER FOR ACCESS TO SUCH MATERIALS IN THE MONTH IN WHICH SUCH CAUSE OF ACTION IS ALLEGED TO HAVE ARISEN. FURTHERMORE, CGS AND ABA SHALL HAVE NO RESPONSIBILITY OR LIABILITY FOR DELAYS OR FAILURES DUE TO CIRCUMSTANCES BEYOND THEIR CONTROL.
                <br><br>
                You agree that the foregoing terms and conditions shall survive any termination of its right of access to the materials identified above.
                <br><br>
                In the event you have an appropriate agreement with CGS covering your use of the CUSIP Database (the "Separate Agreement"), your use of the CUSIP Database shall be governed by the terms of the Separate Agreement for so long as it remains in effect.
                <br><br>
                <li>Waiver</li>
                The failure of Morningstar Credit to insist upon strict compliance with any term of these Terms shall not be construed as a waiver with regard to any subsequent failure to comply with such term or provision.
                <br><br>
                <li>Termination</li>
                These Terms will take effect when you first commence using the Site. Morningstar Credit may at any time, in its absolute discretion deny anyone access to the Site or any portion of it. Termination will be effective without notice.
                <br><br>
                <li>Governing Law</li>
                These Terms, your rights and obligations, and all actions contemplated herein shall be governed by the laws of the State of Illinois and the federal laws of the United States applicable therein. The parties agree that the courts of the State of Illinois shall be the exclusive forum for any dispute arising out of these Terms and the parties hereby consent to the personal jurisdiction of such courts. Any claim must be brought in the claimant's individual capacity, and not as a plaintiff or class member in any purported class, collective, representative, multiple plaintiff, or similar proceeding ("Class Action"). The parties expressly waive any ability to maintain any Class Action in any forum.
                <br>
                <br>
                <li>Contact</li>
                If you would like to contact Morningstar Credit regarding these Terms, please contact Morningstar Credit by email at <a href="mailto:credit@morningstar.com">credit@morningstar.com</a>. If you choose to contact Morningstar Credit via email regarding these Terms, please mark the subject heading of your email “Terms and Conditions Inquiry”.
                <br><br>
                <li>Additional Terms</li>
                Access or use of certain material available on or through this Site may be subject to separate written agreements with Morningstar Credit (“Other Agreements”). In the event of a conflict between these Terms and one or more Other Agreement(s), the terms of such Other Agreement(s) shall govern.
                <br><br>
                With the exception of any Other Agreement(s), these Terms represent the entire agreement between you and Morningstar Credit with respect to your access and use of material available on or through the Site, and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written between you and Morningstar Credit with respect to the Site. Any rights not expressly granted herein are reserved. Please note that data collection and usage practices in connection with the Site are set forth in the Privacy Policy.
                <br><br>
                These Terms are personal to you, and you may not assign your rights or obligations to anyone.
                <br><br>
                If any provision in these Terms is invalid or unenforceable under applicable law, the remaining provisions will continue in full force and effect.
                <br><br>
                Effective Date: 01/02/2024
            </ol>
        </td>
      </tr>
      </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
name: "TermsAndConditions",
mounted() {
    this.saveShowMenu(false);
    this.saveShowGetStartedButton(false);
},
beforeDestroy() {
    this.saveShowMenu(true);
    this.saveShowGetStartedButton(true);
},
methods: {
    ...mapActions({
      saveShowMenu: 'signIn/setMenuVisibility',
      saveShowGetStartedButton: 'signIn/setGetStartedVisibility',
    }),
}
};
</script>
<style lang="scss" scoped>
  @import '@mds/constants';
  @import '@mds/fonts';
  @import '@mds/typography';
  @import '@mds/link';


  .section-title {
    @include mds-level-1-heading();
    color: $mds-text-color-primary;
    padding-bottom: 20px;
  }


  .terms-and-conditions {
    @include mds-body-text-l();
    padding: $mds-space-1-x;
  }

  .terms-container {
    max-width: 960px;
    padding: $mds-space-2-x;
    margin: 0 auto;
  }



</style>