<template>
  <div class="mcia-outer-container">
    <div class="mcia-inner-container">
      <div class="mcia-get-started-container">
        <div class="mcia-get-started-title">{{ getStarted.heading }}</div>
        <get-started-page-form form-id="get-started-page" :hideCancel="true" />
      </div>
    </div>
  </div>
</template>

<script>
import GetStartedPageForm from '@/components/GetStartedForm/GetStartedPageForm.vue';
import getStartedData from '@/data/getStarted.json';
import { mapActions } from 'vuex';

export default {
  name: 'GetStarted',
  components: {
    GetStartedPageForm,
  },
  created() {
    this.saveSelectedProducts([]);
  },
  mounted() {
    this.saveShowGetStartedButton(false);
    this.saveShowMenu(false);
  },
  beforeDestroy() {
    this.saveShowMenu(true);
    this.saveShowGetStartedButton(true);
  },
  data() {
    return {
      getStarted: getStartedData,
    };
  },
  methods: {
    ...mapActions({
      saveShowMenu: 'signIn/setMenuVisibility',
      saveShowGetStartedButton: 'signIn/setGetStartedVisibility',
      saveSelectedProducts: 'signIn/setSelectedProducts',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '@mds/constants';
@import '@mds/fonts';
@import '@mds/typography';

.mcia-get-started {
  &-title {
    @include mds-level-2-heading;
    margin: $mds-space-1-x 0;
    width: 100%;
    text-align: center;
  }
  &-container {
    margin: $mds-space-3-x 0;
  }
}

.mcia-inner-container {
  width: 100%;
  max-width: 376px;
  margin-right: auto;
  margin-left: auto;
}
</style>
