<template>
  <div class="disclaimer-container">
      <table cellpadding="0" cellspacing="0" border="0">
        <tr>
          <td class="section-title">
            Disclaimer
          </td>
        </tr>
      <tr>
        <td class="disclaimer">
            © Copyright {{ currentYear }}. All rights reserved. Morningstar Credit Information & Analytics LLC (“Morningstar”), its affiliates and/or their third-party licensors, including, without limitation, Dealview Technologies Limited (collectively, the “Morningstar Parties”) have exclusive proprietary rights in the data and other information contained herein (collectively, the “Content”).
            <br>
            <br>
            The Content is obtained from sources believed to be accurate and reliable. However, because of the possibility of human or mechanical error, the Morningstar Parties do not guarantee the accuracy, adequacy, completeness or availability of the Content and are not responsible for any errors or omissions in it or for the results obtained from its use. THE MORNINGSTAR PARTIES DISCLAIM ALL EXPRESS AND IMPLIED WARRANTIES WITH RESPECT TO THE CONTENT, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF ACCURACY, TIMELINESS, COMPLETENESS, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE.
            <br>
            <br>
            In no event shall the Morningstar Parties have any liability to any person in connection with the Content or its use for any direct, indirect, punitive, special or other forms of consequential damages (including, without limitation, loss of profits or goodwill) even if advised of the possibility of such damages.
            <br>
            <br>
            The Content shall be used for internal purposes only and shall not be used for any unlawful or unauthorized purposes. Dissemination, distribution, or reproduction of the Content in any form is strictly prohibited except with the prior written permission of Morningstar. Any person who accesses or uses the Content is deemed to acknowledge and agree that: (i) misuse or misappropriation of the Content may cause serious damage to Morningstar for which money damages may not constitute a sufficient remedy; (ii) Morningstar shall have the right to obtain an injunction or other equitable relief for any such misuse/misappropriation, without notice or the need to post bond; and (iii) these remedies are in addition to any other available remedies.
            <br>
            <br>
            The Content shall not be deemed to constitute investment advice under applicable state or federal law, including, but not limited to, the Investment Advisers Act of 1940. The Morningstar Parties shall not be responsible for any investment decisions, damages or other losses resulting from use of the Content. The Content shall be not used or disseminated in connection with a prospectus or other offering document with respect to securities or assets of any type. Any financial analysis, observations, ratings (if any) and/or other commentary included as part of the Content are, and must be construed, solely as statements of opinion and not statements of fact or recommendations to purchase, hold or sell any securities or make investment decisions.
            <br>
            <br>
            The Content and the monitoring of transaction(s) by Morningstar referred to in the Content is based on the following information: (i) the Commercial Real Estate Finance Council standardized investor reporting package (CREFC IRP) and/or (ii) information from third-party data sources, including third-party suppliers of property data, loan data, market data, rents, cap rates, and other similar items.
            <br>
            <br>
            Unless the analysis, data, information, reports, and analogous items in the Content are identified as generated by Morningstar, such analysis, data, information, reports and analogous items are from third-party sources of information, which may include servicer reports or trustee reports, as applicable.
            <br><br>
            The Morningstar Parties did not: (i) conduct site visits of any properties, (ii) conduct any legal review, (iii) review any underlying loan documentation or transaction documentation, (iv) engage in discussions with an arranger and/or any person or entity obligated to make payments under any loan, or (v) review any third-party reports prepared as part of any transaction.
            <br><br>
            Morningstar’s ability to continually monitor transaction(s) referred to in the Content is contingent on Morningstar’s continued timely receipt of certain information and data regarding such transaction(s). If any key information is missing, deficient or inadequate, the analysis in the Content may be limited and may be adversely affected.
            <br><br>
            Morningstar is not a credit rating agency. Morningstar’s assessments, risk scores and/or forecasts, including any change or withdrawal thereof, contained in the Content should not be considered or construed as a credit rating or credit rating action. Morningstar’s assessments, risk scores and forecasts contained in the Content are not credit ratings subject to the Securities Exchange Act of 1934 requirements and regulations promulgated thereunder with respect to credit ratings issued by nationally recognized statistical rating organizations.
            <br><br>
            References to DBRS Morningstar credit ratings contained in the Content refer to credit ratings assigned by one of the DBRS Morningstar group of companies. References to DBRS Morningstar rankings contained in the Content refer to Commercial Mortgage Servicer rankings issued by DBRS, Inc. DBRS Morningstar Commercial Mortgage Servicer rankings contained in the Content are not credit ratings and should not be considered or construed as such. DBRS Morningstar Commercial Mortgage Servicer rankings are not credit ratings subject to the Securities Exchange Act of 1934 requirements and regulations promulgated thereunder with respect to credit ratings issued by nationally recognized statistical rating organizations. All DBRS Morningstar credit ratings and other types of credit opinions are subject to disclaimers and certain limitations. Please read these disclaimers and limitations at <a href="http://www.dbrsmorningstar.com/about/disclaimer" target="_blank">http://www.dbrsmorningstar.com/about/disclaimer</a>. Additional information regarding DBRS Morningstar credit ratings and other types of credit opinions, including definitions, policies and methodologies, are available on <a href="http://www.dbrsmorningstar.com" target="_blank">http://www.dbrsmorningstar.com</a>. The DBRS Morningstar group of companies consists of DBRS, Inc., DBRS Limited, DBRS Ratings GmbH and DBRS Ratings Limited. For more information on regulatory registrations, recognitions and approvals of the DBRS Morningstar group of companies, please visit <a href="https://www.dbrsmorningstar.com/research/225752/highlights.pdf" target="_blank">https://www.dbrsmorningstar.com/research/225752/highlights.pdf</a>.
            <br>
            The DBRS Morningstar group of companies are wholly-owned subsidiaries of Morningstar, Inc.
            <br><br>
            Morningstar Credit Information & Analytics LLC is a wholly-owned subsidiary of Morningstar, Inc.
            <br><br>
            If you wish to inquire about reprinting, translating, or using any of the Content other than as provided herein, please contact us by email at <a href="mailto:dealview@morningstar.com">dealview@morningstar.com</a>.
            <br><br>
            Effective Date: September 20, 2023
            <br><br>
            Document Version Number: 20230919-002
        </td>
      </tr>
      </table>
 </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
name: "Disclaimer",
mounted() {
    this.saveShowMenu(false);
    this.saveShowGetStartedButton(false);
},
beforeDestroy() {
    this.saveShowMenu(true);
    this.saveShowGetStartedButton(true);
},
data() {
    return {
      currentYear: new Date().getFullYear(),
    };
},
methods: {
    ...mapActions({
      saveShowMenu: 'signIn/setMenuVisibility',
      saveShowGetStartedButton: 'signIn/setGetStartedVisibility',
    }),
}
};
</script>

<style scoped lang="scss">
  @import '@mds/constants';
  @import '@mds/fonts';
  @import '@mds/typography';
  @import '@mds/link';

  .section-title {
    @include mds-level-1-heading();
    color: $mds-text-color-primary;
    padding-bottom: 20px;
  }

  .disclaimer {
    @include mds-body-text-l();
    padding: $mds-space-1-x;
  }

  .disclaimer-container {
    max-width: 960px;
    padding: $mds-space-2-x;
    margin: 0 auto;
  }
</style>