<template>
  <div>
    <Banner
      :title="newsflashBannerData.title"
      :imageName="newsflashBannerData.backgroundImage"
      :height="newsflashBannerData.bannerHeight"
    />
    <div class="mcia-inner-container">
      <mds-layout-grid gutters="none" class="newsflash-filter-section">
        <mds-row align-horizontal="space-between" class="newsflash-filter-section-1">
          <mds-col :cols="12" :colsAtM="3">
            <span class="newsflash-date-posted-label">Date Posted:</span>
            <mds-button
              id="newsflash-date-filter-btn"
              class="newsflash-date-filter-btn"
              variation="flat"
              icon-right="caret-down"
              :disabled="loading"
              @click="handleDateFilter"
            >
              <b>{{ selectedDateFilter.text }}</b>
            </mds-button>
          </mds-col>
          <mds-col :cols="12" :colsAtM="6">
            <div class="newsflash-pagination-search-section">
              <mds-pagination
                v-if="totalItems > 0 && !loading"
                :total-items="totalItems"
                :page="page"
                :pageSize="pageSize"
                :pageSizes="[pageSize]"
                @mds-pagination-page-changed="handlePagination"
              ></mds-pagination>
            </div>
          </mds-col>
        </mds-row>
        <mds-row>
          <mds-col :cols="3">
            <mds-search-field
              v-model="searchTerm"
              placeholder="Search..."
              label="Search"
              v-debounce="search"
              class="newsflash-list-section-search-field"
              :disabled="loading"
              @mds-search-field-input-cleared="handleInputClear"
            ></mds-search-field>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
      <div v-if="loading">
        <mds-loader/>
      </div>
      <mds-layout-grid gutters="none" v-else-if="filteredItemList.length > 0">
        <mds-row>
          <mds-col :cols="3" class="newsflash-list-section">
            <NewsFlashList
              :items="displayItemList"
              :selectedItem="selectedItem"
              @onItemSelect="handleItemSelect"
            />
          </mds-col>
          <mds-col :cols="9">
            <div class="newsflash-detail-section" v-html="emailContent"></div>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
      <div v-else class="newsflash-empty-state">
        <mds-empty-state title="No Results" message=""></mds-empty-state>
      </div>
    </div>
    <mds-popover
      v-model="toggleDateFilter"
      triggered-by="newsflash-date-filter-btn"
      size="small"
      width="200px"
      :position="['bottom-center']"
    >
      <mds-list-group size="large">
        <mds-list-group-item
          v-for="(item, index) in dateFilterOptions"
          :key="`newsflash-date-filter-${index}`"
          @click.native.prevent="handleFilterSelect(item)"
        >
          <mds-radio-button
            name="selected-example"
            :value="item.value"
            :checked="item.value === selectedDateFilter.value"
          >{{ item.text }}</mds-radio-button>
        </mds-list-group-item>
    </mds-list-group>
    </mds-popover>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsButton } from '@mds/button';
import MdsPagination from '@mds/pagination';
import MdsSearchField from '@mds/search-field';
import MdsEmptyState from '@mds/empty-state'
import MdsPopover from '@mds/popover';
import MdsRadioButton from '@mds/radio-button';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MdsLoader from '@mds/loader';
import Banner from '@/components/Common/Banner.vue';
import NewsFlashList from '@/components/NewsFlash/NewsFlashList.vue';
import { mapActions } from 'vuex';
import BannerData from '@/data/banner.json';

import { getNewsFlashList, getNewsFlashItem, getNewsFlashEmail } from '@/services/newsflashServices';

export default {
  name: "NewsFlash",
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsPagination,
    MdsSearchField,
    MdsEmptyState,
    MdsPopover,
    MdsRadioButton,
    MdsListGroup,
    MdsListGroupItem,
    MdsLoader,
    Banner,
    NewsFlashList,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      itemList: [],
      filteredItemList: [],
      displayItemList: [],
      selectedItem: '',
      searchTerm: '',
      toggleDateFilter: false,
      dateFilterOptions: [
        { value: '', text: 'All' },
        { value: '7', text: 'Last 1 week' },
        { value: '30', text: 'Last 30 days' },
        { value: '60', text: 'Last 60 days' },
        { value: '365', text: 'Last 1 year' },
      ],
      selectedDateFilter: {},
      paginationOptions: {},
      totalItems: 0,
      page: 1,
      pageSize: 20,
      loading: false,
      emailContent: "",
      newsflashBannerData: BannerData.newsflashBanner
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.selectedDateFilter = this.dateFilterOptions[0];
    this.getNewsFlashList();
  },
  mounted() {
    this.saveShowGetStartedButton(false);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    this.saveShowGetStartedButton(true);
  },
  methods: {
    ...mapActions({
      saveShowGetStartedButton: 'signIn/setGetStartedVisibility',
    }),
    getNewsFlashList() {
      this.loading = true;
      this.itemList = [];
      this.filterNewsFlashList();
      getNewsFlashList(this.selectedDateFilter.value).then(async (resp) => {
        if (resp && resp?.length > 0) {
          this.itemList = resp;
          let index = this.itemList.findIndex((item) => item.id === this.id);
          if (index === -1) {
            index = 0;
            if (this.id) {
              await getNewsFlashItem(this.id).then((resp) => {
                if (resp[0]) {
                  this.itemList.unshift(resp[0]);
                }
              });
            }
          }
          window.history.replaceState({}, '', '/newsflash/' + this.itemList[index]?.id);
          this.selectedItem = this.itemList[index]?.id;
          this.filteredItemList = this.itemList;
          this.setDefaultPaginationOptions(index);
          this.filterNewsFlashList();
          this.getNewsFlashEmailContent(this.selectedItem);
        } else {
          window.history.replaceState({}, '', '/newsflash');
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    getNewsFlashEmailContent(id) {
      getNewsFlashEmail(id).then((resp) => {
        this.emailContent = resp;
        this.emailContent = this.emailContent.replaceAll("max-width:600px","max-width:100%");
      })
    },
    handleItemSelect(id) {
      this.selectedItem = id;
      this.getNewsFlashEmailContent(id);
    },
    search() {
      this.setDefaultPaginationOptions();
      this.filterNewsFlashList();
    },
    filterNewsFlashList() {
      this.filteredItemList = this.itemList.filter((item) => {
        return item?.name?.toLowerCase().includes(this.searchTerm?.toLowerCase());
      });
      this.totalItems = this.filteredItemList.length;
      this.displayItemList = this.filteredItemList.filter((item, index) => this.filterByPagination(index));
    },
    filterByPagination(index) {
      return index >= this.paginationOptions.firstItem - 1 && index < this.paginationOptions.lastItem;
    },
    handleInputClear() {
      this.search();
    },
    handleDateFilter() {
      this.toggleDateFilter = true;
    },
    handleFilterSelect(selectedFilter) {
      this.selectedDateFilter = selectedFilter;
      this.toggleDateFilter = false;
      this.getNewsFlashList();
    },
    handleScroll() {
      this.toggleDateFilter = false;
    },
    handlePagination(options) {
      this.paginationOptions = options;
      this.page = options.page;
      this.filterNewsFlashList();
    },
    setDefaultPaginationOptions(index) {
      this.page = index ? this.getPageByIndex(index) : 1;
      this.paginationOptions.firstItem = index ? this.getFirstItem(index) : 1;
      this.paginationOptions.lastItem = index ? this.getLastItem(index) : this.pageSize;
    },
    getPageByIndex(index) {
      return Math.ceil(++index / this.pageSize);
    },
    getFirstItem(index) {
      return ((Math.ceil(++index / this.pageSize) - 1) * this.pageSize) + 1;
    },
    getLastItem(index) {
      return Math.ceil(++index / this.pageSize) * this.pageSize;
    }
  },
};
</script>

<style lang="scss" scoped>
.mcia-inner-container {
  padding: $mds-space-4-x $mds-space-1-and-a-half-x;
}
.newsflash {
  &-list-section {
    padding-right: $mds-space-1-x;
    &-search-field {
      margin-right: $mds-space-half-x;
    }
  }
  &-filter-section {
    padding-bottom: $mds-space-1-and-a-half-x;
    &-1 {
      padding-bottom: $mds-space-1-x;
    }
  }
  &-detail-section {
    border-left: 1px solid #CCCCCC;
    min-height: 100%;
    height: 100%;
    text-align: center;
  }
  &-pagination-search-section {
    text-align: end;
    .mds-pagination {
      display: inline-block
    }
    .mds-search-field {
      display: inline-block;
      width: 300px;
    }
  }
  &-date-filter-btn {
    color: $mds-text-color-primary;
  }
  &-date-posted-label {
    @include mds-body-text-m();
    color: $mds-interactive-color-secondary-hover;
  }
  &-empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
