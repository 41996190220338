<template>
  <div>
    <banner
      :title="researchBannerData.title"
      :imageName="researchBannerData.backgroundImage"
      :height="researchBannerData.bannerHeight"
    />
    <div class="mcia-inner-container">
      <div class="research-subtitle">{{ researchData.subTitle }}</div>
      <mds-layout-grid class="research-section">
        <mds-row align-horizontal="center" style="margin-bottom: 30px;">
          <mds-col align-vertical="center" :cols="3">
            <mds-search-field
              v-model="searchValue"
              size="medium"
              label="Search"
            >
            </mds-search-field>
          </mds-col>
          <mds-col align-vertical="center" :cols="5">&nbsp; </mds-col>
          <mds-col align-vertical="center">
            <mds-form>
              <mds-fieldset horizontal>
                <mds-select
                  size="medium"
                  class="reserch-list-filter-label"
                  :label="researchData.researchType.label"
                  :options="researchData.researchType.options"
                  @change="filterByType=$event"
                >
                </mds-select>
                <mds-select
                  size="medium"
                  class="reserch-list-filter-label"
                  :label="researchData.durations.label"
                  :options="researchData.durations.options"
                  @change="filterByDuration=$event"
                >
                </mds-select>
              </mds-fieldset>
            </mds-form>
          </mds-col>
        </mds-row>
        <mds-row align-horizontal="center">
          <mds-col>
            <mds-table zebra-stripes show-sortable spacious>
              <mds-thead>
                <mds-th
                  v-for="(header, index) in headers"
                  class="research-list-table-column"
                  :key="index"
                  :sortable="header.sortable"
                  :sorted="header.sorted"
                  :style="header.style"
                  @mds-th-sort-changed="
                    handleSortEvent(index, header.fieldName, $event)
                  "
                >
                  {{ header.text }}
                </mds-th>
              </mds-thead>
              <mds-tbody>
                <mds-tr v-for="(row, index) in researchItems" :key="index" >
                  <mds-td
                    v-for="(header, i) in headers"
                    :id="header.fieldName+index"
                    :key="i"
                    :style="header.style"
                  >
                    <template v-if="header.fieldName === 'download'">
                      <mds-button
                        variation="secondary"
                        type="button"
                        icon="download"
                        @click="
                          downloadButtonClick(
                            row['title'],
                            row['researchPaperUrl']
                          )
                        "
                      >
                        Download
                      </mds-button>
                    </template>
                    <template v-else-if="header.fieldName === 'title'">
                      <a :href="getDownloadPageUrl(row['id'])">{{ row[header.fieldName] }}</a>
                    </template>
                    <template v-else-if="header.fieldName === 'description'">
                      {{ row[header.fieldName] }}&nbsp;
                      <Ellipsis
                        :id="'ellipsis' + index"
                        @mouseover.native="showPopover($event, index)"
                        @mouseleave.native="popoverToggle = false"
                      />
                    </template>
                    <template v-else-if="header.fieldName === 'publishDate'">
                      {{
                        getFormattedDate(row[header.fieldName], "MMM DD, YYYY")
                      }}
                    </template>
                    <template v-else>{{ row[header.fieldName] }}</template>
                  </mds-td>
                </mds-tr>
              </mds-tbody>
            </mds-table>
            <div id="noarticles" class="no-articles hide-no-articles">
              No articles found
            </div>
          </mds-col>
        </mds-row>
        <mds-row style="margin-top: 20px; margin-bottom: 50px">
          <mds-col :cols="12">
            <mds-pagination
              show-items-select
              show-items-info
              size="medium"
              :page-sizes="[10, 25, -1]"
              :page-size="25"
              :total-items="researchItems.length"
              :labels="{
                'all': 'All',
                'itemsInfo': 'Viewing {{first}} to {{last}} of {{total}}',
                'of': 'of',
                'next': 'Next',
                'page': 'Page',
                'prev': 'Prev',
                'show': 'Show'
              }">
            </mds-pagination>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
    <div v-if="loading" class="research-section-loader">
      <mds-loader/>
    </div>
    <!-- Popover element to be shown when hovering on a row  -->
    <mds-popover
      v-model="popoverToggle"
      id="popover"
      class="popoverContent"
      width="500px"
      :triggered-by="popoverRowId"
      :position="[
        'bottom-right',
        'right-center',
        'top-right',
        'top-center',
        'top-left',
        'left-center',
        'bottom-left',
        'bottom-center',
      ]"
    >
      <span class="popoverTitle">{{ this.popoverTitle }}</span>
      <p>{{ popoverText }}</p>
      <span class="keyPointsHeading">Download this research {{this.popoverArticleType.toLowerCase()}} to read more about:</span>
      <p v-if="popoverKeyPointsTitle">{{this.popoverKeyPointsTitle}}</p>
      <ul>
        <li v-for="(point, index) in popoverKeyPoints" :key="index" >{{point}}</li>
      </ul>
    </mds-popover>
    <!-- Modal element to show a form when button is clicked  -->
    <mds-modal v-model="toggle" aria-label="Default Modal" :width="'600px'">
      <mds-section
        bold
        border="primary"
        borderPosition="bottom"
        title="Sign up to receive your copy:"
      >
        <template v-slot:mds-section-actions>
          <mds-button-container right-aligned>
            <mds-button
              icon="remove"
              variation="icon-only"
              @click="toggle = !toggle"
            >
              Close
            </mds-button>
          </mds-button-container>
        </template>
        <DownloadResearchForm
          :research-paper-title="researchPaperTitle"
          :research-paper-url="researchPaperUrl"
          @exit="closeModal"
        />
      </mds-section>
    </mds-modal>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import MdsSection from '@mds/section';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsModal from '@mds/modal';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsForm from '@mds/form';
import MdsFieldset from '@mds/fieldset';
import MdsSelect from '@mds/select';

import MdsPagination from '@mds/pagination';
import MdsSearchField from '@mds/search-field';
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import MdsPopover from '@mds/popover';
import usersData from '../data/data.json';
import researchListData from '@/data/researchList.json';
import Banner from '../components/Common/Banner.vue';
import { mapActions } from 'vuex';
import BannerData from '@/data/banner.json';
import { getResearchList } from '@/services/researchService.js';
import MdsLoader from '@mds/loader';

export default {
  name: 'Research-List',
  components: {
    MdsButtonContainer, MdsButton,
    MdsModal,
    MdsSection,
    MdsForm,
    MdsFieldset,
    MdsSelect,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsPagination,
    MdsSearchField,
    MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
    MdsPopover,
    DownloadResearchForm: () =>import('@/components/DownloadResearchForm.vue'),
    Ellipsis: () =>import('@/components/Ellipsis.vue'),
    Banner,
    MdsLoader,
  },
  mounted() {
    this.saveShowGetStartedButton(false);
  },
  beforeDestroy() {
    this.saveShowGetStartedButton(true);
  },
  data() {
    return {
      researchData: researchListData,
      popoverToggle: false,
      popoverRowId: '',
      popoverTitle: '',
      popoverText: '',
      popoverKeyPoints: [],
      popoverKeyPointsTitle: '',
      popoverArticleType: '',
      toggle: this.toggle,
      sortOrder: -1,
      sortColIndex: 1,
      sortColName: '',
      filterByType: 'all',
      filterByDuration: 'all',
      searchValue: '',
      countries: usersData.countries,
      selectedCountry: [],
      researchItems: [],
      researchItemsCopy: [],
      researchPaperTitle: '',
      researchPaperUrl: '',
      researchBannerData: BannerData.researchBanner,
      loading: false,
      headers: [
        {
          text: 'Date Published',
          fieldName: 'publishDate',
          sortable: true,
          sorted: -1,
          style: {
            'vertical-align': 'middle',
            'width': '12%'
          },
        },
        {
          text: 'Research Article',
          fieldName: 'title',
          sortable: true,
          sorted: 0,
          style: {
            'vertical-align': 'middle',
            'width': '25%'
          },
        },
        {
          text: 'Description',
          fieldName: 'description',
          sortable: false,
          sorted: 0,
          style: {
            'vertical-align': 'middle'
          },
        },
        {
          text: 'Type',
          fieldName: 'type',
          sortable: true,
          sorted: 0,
          style: {
            'vertical-align': 'middle',
            'width': '12%'
          },
        },
        {
          text: '',
          fieldName: 'download',
          sortable: false,
          sorted: 0,
          style: {
            'vertical-align': 'middle',
            'width': '125px'
          },
        }
      ],
      formData: {
        name: {
          id: 'name',
          name: 'name',
          label: 'Name',
          required: true,
          error: false,
          errorText: ['Please type your full name'],
          value: '',
        },
        emailAddress: {
          id: 'emailAddress',
          name: 'emailAddress',
          label: 'Email Address',
          required: true,
          error: false,
          errorText: ['Please type your email address'],
          value: '',
        },
        country: {
          id: 'country',
          name: 'country',
          label: 'Country',
          required: true,
          placeholder: 'Select a Country',
          error: false,
          errorText: ['Please select the country you are from'],
        },
        subject: {
          id: 'subject',
          label: '',
          required: false,
          error: false,
          hidden: true,
          value: '[Morningstar Credit] Research Paper Downloaded',
        },
      },
    };
  },
  created: function() {
    this.debouncedGetSearchResult = _.debounce(this.filterList, 500)
    this.getResearchList();
  },
  methods: {
    ...mapActions({
      saveShowGetStartedButton: 'signIn/setGetStartedVisibility',
    }),
    handleResearchItemsChange(){
      if (this.researchItems?.length === 0) {
        document.getElementById("noarticles").classList.remove("hide-no-articles");
        document.querySelector(".mds-pagination").classList.add("hide-no-articles");
      } else {
        document.getElementById("noarticles").classList.add("hide-no-articles");
        document.querySelector(".mds-pagination").classList.remove("hide-no-articles");
      }
    },
    getFormattedDate: function(dateStr, format) {
      return moment(dateStr).format(format);
    },
    getDownloadPageUrl: function(id) {
      return '/download-research/?id=' + id;
    },
    closeModal: function() {
      this.toggle = !this.toggle;
    },
    sortList: function () {
      // sort the row data by column clicked on
      this.researchItems.sort((a, b) => {
        if (this.sortColName === 'publishDate')
          return moment(a[this.sortColName]).isSameOrAfter(moment(b[this.sortColName])) ? this.sortOrder : -this.sortOrder;
        else
          return a[this.sortColName] >= b[this.sortColName] ? this.sortOrder : -this.sortOrder;
      });
    },
    filterList: function () {
      //create a copy of the full list of research articles
      this.researchItems = this.researchItemsCopy.slice();
      //Filter by Type if selected
      if (this.filterByType !== 'all') {
        this.researchItems = this.researchItems.filter(item => item.type === this.filterByType);
      }
      //Filter by duration if selected
      if (this.filterByDuration != 'all') {
        var dur = 3;
        switch (this.filterByDuration) {
          case 'last3months':
            dur = 3;
            break;
          case 'last6months':
            dur = 6;
            break;
          case 'last9months':
            dur = 9;
            break;
          case 'lastyear':
            dur = 12;
            break;
        }
        this.researchItems = this.researchItems.filter((item) => {
          return moment(item.publishDate).isBetween(
            moment().subtract(dur, "months"),
            moment()
          );
        });
      }
      //Sort the list
      this.sortList();
      //if search is performed by user
      if (this.searchValue !== null && this.searchValue !== '') {
        var searchTerm = this.searchValue.toLowerCase();
        this.researchItems = this.researchItems.filter(item => {
          return (
            item.title.toLowerCase().indexOf(searchTerm) >= 0 ||
            item.description.toLowerCase().indexOf(searchTerm) >= 0
          );
        });
      }
    },
    handleSortEvent: function (colIndex, colName) {
      // if your tracked sort order is 0 (none), set it 1 (ascending)
      // if there is already an order being tracked inverse it
      this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;
      // if the table was already sorted by a different column, then reset that column to 0 (none)
      if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
        this.$set(this.headers[this.sortColIndex], "sorted", 0);
        this.sortOrder = 1;
      }
      // track the current column as the one being sorted on
      this.sortColIndex = colIndex;
      this.$set(this.headers[colIndex], "sorted", this.sortOrder);
      // sort the row data by column clicked on
      this.sortColName = colName;
      this.sortList();
    },
    downloadButtonClick: function (title, url) {
      this.researchPaperTitle = title;
      this.researchPaperUrl = url;
      this.toggle = !this.toggle;
    },
    showPopover(e, rowIndex) {
      if (e.target.id.indexOf("ellipsis") < 0) {
        this.popoverToggle = false;
        return false;
      }
      this.popoverRowId = e.target.id;
      this.popoverTitle = this.researchItems[rowIndex].title;
      this.popoverArticleType = this.researchItems[rowIndex].type;
      this.popoverText = this.researchItems[rowIndex].description;
      this.popoverKeyPoints = this.researchItems[rowIndex].keyPoints;
      this.popoverToggle = true;
      this.popoverKeyPointsTitle = this.researchItems[rowIndex].keyPointsTitle;
    },
    getResearchList() {
      this.loading = true;
      getResearchList()
        .then(async (resp) => {
          if (resp?.length > 0) {
            this.researchItems = resp;
            this.researchItemsCopy = resp;
          } else {
            this.researchItems = [];
            this.researchItemsCopy = [];
          }
        })
        .catch(() => {
          this.handleResearchItemsChange();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    filterByType: function () {
      this.filterList();
    },
    filterByDuration: function () {
      this.filterList();
    },
    searchValue: function() {
      this.debouncedGetSearchResult();
    },
    researchItems: function(){
      this.handleResearchItemsChange();
    },
  }};
</script>

<style scoped lang="scss">
@import '@mds/constants';
@import '@mds/fonts';
@import '@mds/typography';
@import '@mds/link';

.popoverTitle {
  @include mds-level-6-heading($bold: true);
}

.keyPointsHeading {
  @include mds-body-text-m($bold: true);
}

.research-section {
  height: auto;
  width: 100%;
  margin: 0 auto;
}
.research-section-loader,
.research-section-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}

.research-subtitle {
  @include mds-level-2-heading($bold: true);
  color: $mds-text-color-primary;
  height: auto;
  margin-top: $mds-space-5-x;
  margin-bottom: $mds-space-3-x;
}

.no-articles {
  @include mds-level-5-heading();
  color: $mds-text-color-error;
  text-align: center;
  height: auto;
  width: 100%;
  margin-top: $mds-space-5-x;
  margin-bottom: $mds-space-3-x;
}

.popoverContent {
  @include mds-body-text-m();
}

.hide-no-articles {
  display: none;
}

.form {
  margin-top: 40px;
  margin-bottom: 100px;
}

.form-title {
  @include mds-level-3-heading;
  text-align: center;
}

.mds-modal-body {
  overflow-x: hidden;
  overflow-y: auto;
  /*padding-left: 10px;*/
  padding-right: 10px;
  max-height: calc(90vh - 79px);
}

a:visited {
  color: $mds-text-color-interactive-primary !important;
}

.reserch-list-filter-label {
  ::v-deep {
    .mds-label__text {
      color: $mds-text-color-secondary;
    }
  }
}

.research-list-table-column {
  ::v-deep {
    .mds-th__inner .mds-th__button {
      @include mds-level-4-heading($bold: true);
      color: $mds-text-color-secondary;
    }
    .mds-th__text {
       @include mds-level-4-heading($bold: true);
       color: $mds-text-color-secondary;
    }
  }
}

.mds-td {
   color: $mds-text-color-secondary;
}

.mds-th {
    border-bottom:$mds-border-separator;
}

</style>