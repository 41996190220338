<template>
  <div>
    <VideoDetails :videoId="id"/>
  </div>
</template>

<script>
import VideoDetails from "@/components/VideoDetails.vue";

export default {
  name: "Videos",
  components: {
    VideoDetails
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.$contactType =
      this.id.toLowerCase() === "analytics-launch" ? "cre_analytics" : "common";
  },
  destroyed() {
    this.$contactType = "common";
  },
};
</script>

<style lang="scss">
</style>