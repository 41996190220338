<template>
  <div class="access-denied-view">
    <mds-empty-state
      size="large"
      title="Access Denied"
      message="You are not permitted to access this page."
    >
      <template v-slot:mds-empty-state-icon>
        <img alt="Access Denied - Lock" role="presentation" :src="lockImg" />
      </template>
    </mds-empty-state>
  </div>
</template>

<script lang="js">
// import AppMessage from '@/config/i18n';
import MdsEmptyState from '@mds/empty-state';
import lockClosed from '../../material/lockClosed.svg';

export default {
  name: 'AccessDenied',
  // i18n: {
  //   ...AppMessage,
  // },
  components: {
    MdsEmptyState,
  },
  computed: {
    lockImg() {
      return lockClosed;
    },
  },
};
</script>

<style lang="scss" scoped>
.access-denied-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 180px;
  ::v-deep {
    .mds-empty-state__icon___dealview {
      width: fit-content;
    }
  }
}
</style>
