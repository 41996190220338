<template>
  <div>
    <p class="heading">Thank you for getting in touch!</p>
    <p class="sub-heading">
      Your message has been successfully sent. We will contact you as soon as possible.
    </p>
    <p>
      <router-link to="/" class="remove-underline"
        ><mds-button variation="secondary" size="medium" class="button"
          >Home</mds-button
        ></router-link
      >
    </p>
  </div>
</template>

<script>
import { MdsButton } from "@mds/button";
import { mapActions } from 'vuex';

export default {
  name: "GetStartedThankYou",
  components: {
    MdsButton,
  },
  mounted() {
    this.saveShowGetStartedButton(false);
  },
  beforeDestroy() {
    this.saveShowGetStartedButton(true);
  },
  methods: {
    ...mapActions({
      saveShowGetStartedButton: 'signIn/setGetStartedVisibility',
    }),
  }
};
</script>

<style scoped lang="scss">
@import "@mds/constants";
@import "@mds/fonts";
@import "@mds/typography";


.heading {
  @include mds-level-2-heading();
  text-align: center;
  font-weight: 700;
  margin-top: 180px;
  margin-bottom: 0;
}
.sub-heading {
  @include mds-level-5-heading();
  line-height: 26px;
  text-align: center;
}

.button {
  margin: auto;
  display: block;
}

.remove-underline {
  text-align: center;
  text-decoration: none;
}
</style>